<template>
  <modal
    name="privacy-policy-dialog"
    width="60%"
    height="75%"
  >
    <div class="dialog-wrapper padded">
      <h1
        slot="title"
        class="dialog-title"
      >
        Privacy Policy
      </h1>

      <div class="dialog-body prose mx-auto text-white">
        <p>
          The following is a list of what information we collect and how it is used:
        </p>

        <ul>
          <li>
            <em>Your Name</em><br>
            This is the "Your Name" field you provide to us in the payment form.  This is used to
            display on the front page so long as you have paid the most, and then to display on the
            payment history page.  This value is also provided to Stripe, our payment provider.
          </li>

          <li>
            <em>Email</em><br>
            This is the "Email" field you provide to us in the payment form.  This is used to email
            you when someone else pays more than you, <strong>provided</strong> you have also
            checked the checkbox labeled "Send me an email if someone pays more than me".  This
            value is also provided to Stripe, our payment provider, to send you a record of your
            purchase.  We may also use this value to contact you in the case of a security breach,
            change of Terms of Service or Privacy Policy, or other critical information regarding
            the site or your information.  This value will <strong>not</strong> be provided or
            resold to a third party, except in situations where the entire Website is sold to
            another party.
          </li>

          <li>
            <em>Your Social Website Address</em><br>
            This is the "Your Social" field you optionally provide to us in the payment form.  This
            is used to link to on the front page so long as you have paid the most, and then to
            display on the payment history page.
          </li>

          <li>
            <em>Your Message</em><br>
            This is the "Your Message" field you provide to us in the payment form.  This is used to
            display on the front page so long as you have paid the most, and then to display on the
            payment history page.
          </li>

          <li>
            <em>Billing Address</em><br>
            This is the "Billing Address" field you provide to us in the payment form.  This is
            securely provided to Stripe, our payment provider in order to complete your purchase and
            reduce fraud.  This value only stored with Stripe.
          </li>

          <li>
            <em>Country</em><br>
            This is the "Country" field you provide to us in the payment form.  This is securely
            provided to Stripe, our payment provider in order to complete your purchase and reduce
            fraud.  This value only stored with Stripe.
          </li>

          <li>
            <em>State</em><br>
            This is the "State" field you provide to us in the payment form.  This is securely
            provided to Stripe, our payment provider in order to complete your purchase and reduce
            fraud.  This value only stored with Stripe.
          </li>

          <li>
            <em>City</em><br>
            This is the "City" field you provide to us in the payment form.  This is securely
            provided to Stripe, our payment provider in order to complete your purchase and reduce
            fraud.  This value only stored with Stripe.
          </li>

          <li>
            <em>Postal Code</em><br>
            This is the "Zip / Postal Code" field you provide to us in the payment form.  This is
            securely provided to Stripe, our payment provider in order to complete your purchase and
            reduce fraud.  This value only stored with Stripe.
          </li>

          <li>
            <em>Credit Card Details</em><br>
            This is the "Card number", "MM/YY" (month/year), and "CVC" fields you provide to us in
            the payment form.  This is securely provided to Stripe, our payment provider in order
            to complete your purchase and reduce fraud.  This value only stored with Stripe.
          </li>
        </ul>

        <p>
          If you wish to have any of this information redacted at any time, please email
          <a href="mailto:hello@ipaidthemost.com">hello@ipaidthemost.com</a> from the email address
          associated with the payment.  Information will be redacted, not altered.  If you wish to
          have a different message or Social Website Address listed, you may do it in the normal
          method of paying the most, and having it be displayed in a new message.
        </p>
      </div>

      <div class="dialog-buttons">
        <button
          class="py-2 px-4 rounded focus:ring bg-indigo-300 hover:bg-indigo-700 hover:underline"
          @click="closeDialog"
        >
          Close
        </button>
      </div>
    </div>
  </modal>
</template>

<script>
export default {
  name: 'PrivacyPolicyDialog',

  methods: {
    /**
     * Opens the dialog to display the terms of service.
     */
    openDialog() {
      this.$modal.show('privacy-policy-dialog');
    },

    /**
     * Close the dialog.
     */
    closeDialog() {
      this.$modal.hide('privacy-policy-dialog');
    },
  },
};
</script>

<style scoped>
.prose strong {
  @apply text-white font-bold;
}

.prose a {
  @apply text-white;
}
</style>
