<template>
  <modal
    name="tos-dialog"
    width="60%"
    height="75%"
  >
    <div class="dialog-wrapper padded">
      <h1
        slot="title"
        class="dialog-title"
      >
        Terms of Service
      </h1>

      <div class="dialog-body prose mx-auto text-white">

        <p>
          I PAID THE MOST (the "Website") is a website run by Perihelion Heavy Industries which
          offers as a service the ability for a customer (the "Payer") to pay more money than the
          previous payer.  Payer will then have their "Name", "Email", "Social Website Address", and
          a "Short Message" displayed on the front page of the Website, as well as added to the
          payment history section of the Website.  By clicking the "Pay" button on the order form,
          you agree to be bound by the terms of this Terms of Service, that you are 16 years of age
          or older, and that you have the right and power to enter into this agreement.
        </p>

        <ol>
          <li>
            <em>Don't be shitty.</em><br>
            Payer agrees that their Name, Email, Social Website Address, and Short Message will not
            be "Shitty", whereas Shitty is the quality of being mean and hurtful to specific people
            or groups or just in general, as decided by the sole descretion of Perihelion Heavy
            Industries.  A non-exhaustive list of Shitty examples that are <strong>not</strong>
            allowed follows:
            <ul>
              <li>Racism</li>
              <li>Misogyny</li>
              <li>Transphobia</li>
              <li>Hate speech</li>
              <li>Incitement to violence</li>
            </ul>
            For the above reasons, <strong>or for any other reason</strong> at the sole discretion
            of Perihelion Heavy Industries, a Name, Email, Social Website Address, or Short Message
            may be removed or altered.  Don't be Shitty.
          </li>

          <li>
            <em>You may be replaced at any time.</em><br>
            Once someone else pays more than the Payer, their Name, Email, Social Website Address,
            and Short Message will replace the Payer's on the front page and at the top of the
            payment history page until such time as the Payer decides to again pay more than the
            most-recent payer, whereupon their new Name, Email, Social Website Address, and Short
            Message will replace the current ones.  Payer understands that their Name, Email, Social
            Website Address, and Short Message can be removed from the front page by this method at
            any time.
          </li>

          <li>
            <em>No guarantees.</em><br>
            The Website is offered with no warranty or guarantee whatsoever. If Payer chooses to use
            the Website to advertise their services or themselves and it goes offline, that's on
            you, bud.  The internet is a terrible place and things go offline temporarily and
            permanently all the time. Payer understands that the Website is for entertainment
            purposes only, and must not be relied on for any services or reason outside of their
            brief and personal enjoyment.
          </li>

          <li>
            <em>Payment and delivery terms.</em><br>
            Payer's Name, Email, Social Website Address, and Short Message will appear on the
            website once the Payer's payment is approved and clears through Stripe, our payment
            partner.  Refunds will <strong>only</strong> be issued in cases of fraud, financial
            abuse, or when compelled by law.  Payer's Name, Email, Social Website Address, and Short
            Message may be removed at this time, or replaced with a humorous (humour to be
            determined <strong>solely</strong> by Perihelion Heavy Industries) alternative Name,
            Email, Social Website Address, or Short Message.
          </li>

          <li>
            <em>Intellectual property grant.</em><br>
            Payer grants Perihelion Heavy Industries a worldwide, royalty-free, perpetual,
            nonexclusive, transferable, sublicensable and irrevocable license to reprint or
            otherwise use Payer's Name, Social Website Address and Short Message (but
            <strong>not</strong> Email) in any manner and for any purpose.
          </li>

          <li>
            <em>Jurisdiction.</em><br>
            The courts of Toronto, Ontario, Canada shall have exclusive jurisdiction to settle any
            dispute or claim arising out of or in connection with this agreement or its subject
            matter or formation (including non-contractual disputes or claims).
          </li>

          <li>
            <em>Enforcability.</em><br>
            If any term or provision of this agreement is invalid, illegal, or unenforceable in any
            jurisdiction, such invalidity, illegality, or unenforceability shall not affect any
            other term or provision of this agreement or invalidate or render unenforceable such
            term or provision in any other jurisdiction.
          </li>

          <li>
            <em>Headings.</em><br>
            The headings in this Agreement are for reference only and do not affect the
            interpretation of this Agreement.
          </li>
        </ol>
      </div>

      <div class="dialog-buttons">
        <button
          class="py-2 px-4 rounded focus:ring bg-indigo-300 hover:bg-indigo-700 hover:underline"
          @click="closeDialog"
        >
          Close
        </button>
      </div>
    </div>
  </modal>
</template>

<script>
export default {
  name: 'TosDialog',

  methods: {
    /**
     * Opens the dialog to display the terms of service.
     */
    openDialog() {
      this.$modal.show('tos-dialog');
    },

    /**
     * Close the dialog.
     */
    closeDialog() {
      this.$modal.hide('tos-dialog');
    },
  },
};
</script>

<style scoped>
.prose strong {
  @apply text-white font-bold;
}
</style>
