<template>
  <div class="container pt-24 md:pt-36 mx-auto flex flex-wrap flex-col md:flex-row items-center justify-center">
    <div class="flex flex-col w-full xl:w-2/5 justify-center lg:items-start overflow-y-hidden">
      <h1 class="text-3xl md:text-5xl text-white opacity-75 font-bold leading-tight text-center">
        The
        <span class="bg-clip-text text-transparent bg-gradient-to-r from-yellow-400 via-yellow-50 to-yellow-400">
          Rarest
        </span>
        Site On The Internet
      </h1>
      <p class="leading-normal text-base md:text-xl my-8 text-center text-center w-full">
        Only one person can have paid the most.  Will it be you?
      </p>

      <div class="bg-gray-900 opacity-75 w-full shadow-lg rounded-lg px-8 pt-6 pb-4">
        <div
          class="mb-4 text-xl"
          v-if="theMost"
        >
          <a
            class="text-yellow-300 font-bold"
            target="_blank"
            :href="theMost.value('social')"
            v-if="theMost.value('social')"
          >
            {{ theMost.value('name') }}
          </a>
          <span
            class="text-yellow-300 font-bold"
            v-else
          >
            {{ theMost.value('name') }}
          </span>
          paid
          <span class="text-green-400 font-bold">
            {{ theMost.value('amountDollars') }}
          </span>
          to say:

          <blockquote class="block text-blue-300 py-2 font-bold mb-2 border-l-4 border-blue-300 pl-4 mt-4">
            {{ theMost.value('message') }}
          </blockquote>
        </div>
        <div
          class="text-center"
          v-else
        >
          <i class="fas fa-money-bill-wave fa-spin fa-3x text-green-400 my-8" />
        </div>
      </div>

      <button
        class="bg-yellow-400 text-black font-bold py-2 px-4 rounded focus:ring mt-4 mx-auto block hover:bg-yellow-300 hover:underline disabled:bg-gray-400 disabled:no-underline disabled:cursor-default"
        type="button"
        :disabled="isLoading"
        @click="$refs.paymentDialog.openDialog()"
      >
        I bet I can pay more...
      </button>
    </div>

    <payment-dialog ref="paymentDialog" />
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import PaymentDialog from '../components/PaymentDialog';

export default {
  name: 'HomePage',

  components: {
    PaymentDialog,
  },

  computed: {
    ...mapState('Payments', [
      'theMost',
    ]),

    /**
     * If we're still loading The Most payment.
     *
     * @return {Boolean}
     */
    isLoading() {
      return this.theMost === null;
    }
  },

  async mounted() {
    await this.getTheMost();
  },

  methods: {
    ...mapActions('Payments', [
      'getTheMost',
    ]),
  }
};

</script>
