const { countries, states } = require('#config/geography');
const { Validator } = require('#lib/Validator');
const { Rule, NotRule } = require('#lib/Rules');
const {
  ERROR_EMPTY,
  ERROR_INVALID_EMAIL,
  ERROR_INVALID_URL,
  ERROR_NOT_IN_LIST,
} = require('#lib/Errors');

const MAX_NAME_LENGTH = 30;
const MAX_SOCIAL_LENGTH = 200;
const MAX_MESSAGE_LENGTH = 200;

// Rules use the validator.js library to validate strings from your forms.
// For documentation about Nodewood Validators, visit: https://nodewood.com/docs/architecture/validators/
// For documentation about the validator.js library, visit: https://www.npmjs.com/package/validator

class PaymentValidator extends Validator {
  constructor(fields) {
    super(fields);

    this.rules = {
      name: [
        new Rule('isEmpty', { code: ERROR_EMPTY, title: 'You must enter a name.' }),
        new NotRule(
          'isLength',
          {
            code: ERROR_EMPTY,
            title: `Your name may not be longer than ${MAX_NAME_LENGTH}.`,
            meta: { maxLength: MAX_NAME_LENGTH },
          },
          [{ max: MAX_NAME_LENGTH }],
        ),
      ],
      social: [
        new NotRule(
          'isLength',
          {
            code: ERROR_EMPTY,
            title: `Your social may not be longer than ${MAX_SOCIAL_LENGTH}.`,
            meta: { maxLength: MAX_SOCIAL_LENGTH },
          },
          [{ max: MAX_SOCIAL_LENGTH }],
        ),
        new NotRule(
          'isURL',
          {
            code: ERROR_INVALID_URL,
            title: 'Your social must be a valid URL link.',
          },
        ),
      ],
      message: [
        new Rule('isEmpty', { code: ERROR_EMPTY, title: 'You must enter a message.' }),
        new NotRule(
          'isLength',
          {
            code: ERROR_EMPTY,
            title: `Your message may not be longer than ${MAX_MESSAGE_LENGTH}.`,
            meta: { maxLength: MAX_MESSAGE_LENGTH },
          },
          [{ max: MAX_MESSAGE_LENGTH }],
        ),
      ],
      email: [
        new Rule('isEmpty', { code: ERROR_EMPTY, title: 'You must enter an email address.' }),
        new NotRule('isEmail', { code: ERROR_INVALID_EMAIL, title: 'You must enter a valid email address.' }),
      ],
      amount: [
        new Rule('isEmpty', { code: ERROR_EMPTY, title: 'Missing payment amount.' }),
      ],
      line1: [
        new Rule('isEmpty', { code: ERROR_EMPTY, title: 'You must enter an address.' }),
      ],
      country: [
        new Rule('isEmpty', { code: ERROR_EMPTY, title: 'You must enter a country.' }),
        new NotRule(this.countryInList, {
          code: ERROR_NOT_IN_LIST,
          title: 'You must select a valid country.',
          meta: { list: countries },
        }),
      ],
      state: [
        new Rule('isEmpty', { code: ERROR_EMPTY, title: 'You must enter a state/province.' }),
        new NotRule(this.stateInList, this.stateNotInListError),
      ],
      city: [
        new Rule('isEmpty', { code: ERROR_EMPTY, title: 'You must enter a city.' }),
      ],
      postal_code: [
        new Rule('isEmpty', { code: ERROR_EMPTY, title: 'You must enter a zip/postal code.' }),
      ],
      send_more_agree: [
        new Rule('isBoolean', { code: ERROR_NOT_IN_LIST, title: 'Invalid "send email" choice.' }),
      ],
    };
  }

  /**
   * Validates if provided country is in the list of accepted countries.
   *
   * @param {String} country - The 2-letter country code.
   *
   * @return {Boolean}
   */
  countryInList(country) {
    return Object.keys(countries).includes(country);
  }

  /**
   * If there is a defined list of states for the form's country, ensure provided state is in that
   * list.  Otherwise, any state is fine.
   *
   * @param {String} state - The state to check.
   *
   * @return {Boolean}
   */
  stateInList(state) {
    return Object.keys(states).includes(this.form.country)
      ? states[this.form.country].includes(state)
      : true;
  }

  /**
   * Returns the error object for when a state is not in the accepted list.
   *
   * @return {Object}
   */
  stateNotInListError() {
    return {
      code: ERROR_NOT_IN_LIST,
      title: 'You must select a valid state / province.',
      meta: { list: states[this.form.country] },
    };
  }
}

module.exports = {
  PaymentValidator,

  PAYMENT_REQUEST_FIELDS: [
    'name',
    'social',
    'message',
    'email',
    'amount',
    'send_more_agree',
  ],

  PAYMENT_FORM_FIELDS: [
    'name',
    'social',
    'message',
    'email',
    'line1',
    'country',
    'state',
    'city',
    'postal_code',
  ],
};
