const { get, isEmpty: isEmptyArray } = require('lodash');
const { isEmpty: isEmptyString } = require('validator');

/**
 * Extracts the titles from an array of errors and joins them by a <br>.
 *
 * @param {array} errors - The errors to extract the titles from.
 *
 * @return {String}
 */
function getErrorTitleString(errors) {
  return errors.map((error) => error.title).join('<br>');
}

/**
 * Computed helper to make it easy to get field error text.
 *
 * @param {String} field - The name of the field to get error text for.
 * @param {Validator} validator - The validator to use.
 * @param {String} formName - The name of the form variable on the component.
 * @param {String} apiErrorsName - The name of the api errors variable on the component.
 *
 * @return {String}
 */
function fieldErrorText(field, validator, formName = 'form', apiErrorsName = 'apiErrors') {
  return function () { // eslint-disable-line func-names
    const form = get(this, formName);
    const apiErrors = get(this, apiErrorsName);

    return isEmptyString(form[field]) && isEmptyArray(get(apiErrors, field, []))
      ? ''
      : getErrorTitleString(validator.errors(form, apiErrors, field));
  };
}

module.exports = {
  getErrorTitleString,
  fieldErrorText,
};
