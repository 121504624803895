<template>
   <modal
    name="payment-dialog"
    :min-width="400"
    :max-width="500"
    :adaptive="true"
    height="auto"
    width="40%"
  >
    <div class="dialog-wrapper padded">
      <h2
        class="text-xl mb-4"
      >
        Pay
        <span class="text-green-400 font-bold">{{ formattedPayAmount }}</span>
        to Pay The Most!
      </h2>

      <form
        class="dialog-body"
        :class="{ 'opacity-50': isPayLoading }"
        @submit.prevent="submit"
      >
        <text-input
          v-model="form.name"
          input-id="name"
          label="Your Name"
          class="w-full text-black mb-4 text-input-small"
          :error-text="nameErrorText"
          :disabled="isPayLoading"
        />

        <text-input
          v-model="form.email"
          input-id="email"
          label="Email"
          class="w-full text-black mb-4 text-input-small"
          :error-text="emailErrorText"
          :disabled="isPayLoading"
        />

        <text-input
          v-model="form.social"
          input-id="social"
          label="Your Social (Twitter, Insta, Website, etc. -- Optional)"
          class="w-full text-black mb-4 text-input-small"
          :error-text="socialErrorText"
          :disabled="isPayLoading"
        />

        <text-input
          v-model="form.message"
          input-id="message"
          label="Your Message (Don't Be Shitty)"
          class="w-full text-black mb-4 text-input-small"
          :error-text="messageErrorText"
          :disabled="isPayLoading"
        />

        <hr class="my-4">

        <text-input
          v-model="form.line1"
          class="mb-2 text-black text-input-small"
          input-id="line1"
          label="Billing Address"
          :error-text="addressErrorText"
          :disabled="isPayLoading"
        />

        <div class="flex justify-between mb-4">
          <country-input
            v-model="form.country"
            class="w-1/2 mr-2 text-black text-input-small"
            label="Country"
            :error-text="countryErrorText"
            :disabled="isPayLoading"
          />
          <state-input
            v-model="form.state"
            class="w-1/2 flex items-end text-black text-input-small"
            label="State"
            :error-text="stateErrorText"
            :country="form.country"
            :disabled="isPayLoading"
          />
        </div>

        <div class="flex justify-between mb-4">
          <text-input
            v-model="form.city"
            class="mr-2 w-full text-black text-input-small"
            input-id="city"
            label="City"
            :error-text="cityErrorText"
            :disabled="isPayLoading"
          />

          <text-input
            v-model="form.postal_code"
            class="w-full text-black text-input-small"
            input-id="postal_code"
            label="Zip / Postal Code"
            :error-text="postalCodeErrorText"
            :disabled="isPayLoading"
          />
        </div>

        <div class="mb-3">
          <div
            id="card-element"
            class="bg-white"
          />

          <div
            role="alert"
            class="text-sm text-red-500"
          >
            {{ cardErrors }}
            {{ generalErrorText }}
          </div>
        </div>

        <div class="text-sm mb-2">
          <label class="inline cursor-pointer">
            <input
              v-model="tosAgree"
              type="checkbox"
              class="mr-2"
              :disabled="isPayLoading"
            >
            I agree to the
          </label>
          <a
            class="whitespace-nowrap cursor-pointer text-indigo-200 hover:underline"
            @click="$refs.tosDialog.openDialog()"
          >
            Terms of Service
          </a>
          and
          <a
            class="whitespace-nowrap cursor-pointer text-indigo-200 hover:underline"
            @click="$refs.privacyPolicyDialog.openDialog()"
          >
            Privacy Policy
          </a>.
        </div>

        <div class="text-sm mb-4">
          <label class="inline cursor-pointer">
            <input
              v-model="sendMoreAgree"
              type="checkbox"
              class="mr-2"
              :disabled="isPayLoading"
            >
            Send me an email if someone pays more than me.
          </label>
        </div>
      </form>

      <div class="dialog-buttons">
        <i
          class="fas fa-money-bill-wave fa-spin fa-2x text-green-400 "
          v-if="isPayLoading"
        />

        <button
          class="py-2 px-4 rounded focus:ring bg-indigo-300 hover:bg-indigo-700 hover:underline disabled:bg-gray-400 disabled:no-underline disabled:cursor-default"
          @click="closeDialog"
          :disabled="isPayLoading"
        >
          Cancel
        </button>

        <button
          class="py-2 px-4 rounded focus:ring bg-indigo-500 hover:bg-indigo-700 hover:underline disabled:bg-gray-400 disabled:no-underline disabled:cursor-default"
          @click="submit"
          :disabled="! formValid || isPayLoading"
        >
          Pay {{ formattedPayAmount }} USD
        </button>
      </div>
    </div>

    <tos-dialog ref="tosDialog" />
    <privacy-policy-dialog ref="privacyPolicyDialog" />
  </modal>
 </template>

<script>
import { mapActions, mapState } from 'vuex';
import numbro from 'numbro';
import TextInput from '#ui/components/TextInput';
import CountryInput from '#ui/components/CountryInput';
import StateInput from '#ui/components/StateInput';
import { getErrorTitleString, fieldErrorText } from '#ui/lib/forms';
import {
  PaymentValidator,
  PAYMENT_FORM_FIELDS,
} from '#features/payments/lib/validators/PaymentValidator';
import TosDialog from './TosDialog';
import PrivacyPolicyDialog from './PrivacyPolicyDialog';

const validator = new PaymentValidator(PAYMENT_FORM_FIELDS);

export default {
  name: 'PaymentDialog',

  components: {
    TextInput,
    CountryInput,
    StateInput,
    TosDialog,
    PrivacyPolicyDialog,
  },

  data: () => ({
    isPayLoading: false,
    form: {
      name: '',
      email: '',
      social: '',
      message: '',

      line1: '',
      country: '',
      state: '',
      city: '',
      postal_code: '',
    },
    apiErrors: {
      name: [],
      email: [],
      social: [],
      message: [],

      line1: [],
      country: [],
      state: [],
      city: [],
      postal_code: [],
      general: [],
    },

    stripe: null,
    card: null,
    cardErrors: '',
    cardValid: false,

    tosAgree: false,
    sendMoreAgree: false,
  }),

  computed: {
    ...mapState('Payments', [
      'theMost',
    ]),

    nameErrorText: fieldErrorText('name', validator),
    emailErrorText: fieldErrorText('email', validator),
    socialErrorText: fieldErrorText('social', validator),
    messageErrorText: fieldErrorText('message', validator),

    addressErrorText: fieldErrorText('line1', validator),
    countryErrorText: fieldErrorText('country', validator),
    stateErrorText: fieldErrorText('state', validator),
    cityErrorText: fieldErrorText('city', validator),
    postalCodeErrorText: fieldErrorText('postal_code', validator),

    /**
     * Form is valid if all inputs are full and valid.
     *
     * @return {Boolean}
     */
    formValid() {
      return validator.valid(this.form)
        && this.cardValid
        && this.tosAgree;
    },

    /**
     * Generate error text for general errors.
     *
     * @return {String}
     */
    generalErrorText() {
      return getErrorTitleString(this.apiErrors.general);
    },

    /**
     * Gets the next payment amount in cents.
     *
     * @return {Number}
     */
    nextAmountCents() {
      return this.theMost !== null ? this.theMost.getNextAmountCents() : 0;
    },

    /**
     * The formatted amount the user has to pay to pay the most.
     *
     * @return {String}
     */
    formattedPayAmount() {
      return numbro(this.nextAmountCents / 100).formatCurrency({
        thousandSeparated: true,
        mantissa: 2,
        spaceSeparated: false,
      });
    }
  },

  methods: {
    ...mapActions('Payments', [
      'pay',
    ]),

    /**
     * Reset form errors arising from the API.
     */
    resetApiErrors() {
      this.cardErrors = '';
      this.apiErrors = {
        name: [],
        email: [],
        social: [],
        message: [],
        line1: [],
        country: [],
        state: [],
        city: [],
        postal_code: [],
        general: [],
      };
    },

    /**
     * Opens the dialog to display the terms of service.
     */
    openDialog() {
      this.$modal.show('payment-dialog');

      setTimeout(async function () {
        const style = {
          base: {
            color: '#000',
            fontSize: '16px',
            backgroundColor: '#fff',
          },
        };

        this.stripe = Stripe(process.env.VUE_APP_STRIPE_PK); // eslint-disable-line new-cap, no-undef
        this.card = this.stripe.elements().create('card', { style, hidePostalCode: true });
        this.card.mount('#card-element');

        this.card.on('change', ({ complete, error }) => {
          this.cardValid = complete;
          this.cardErrors = error ? error.message : '';
        });
      }.bind(this), 100);
    },

    /**
     * Close the dialog.
     */
    closeDialog() {
      this.$modal.hide('payment-dialog');
    },

    /**
     * Submit the dialog, create the new project.
     */
    async submit() {
      this.resetApiErrors();

      if (! this.formValid) {
        this.apiErrors = {
          ...validator.errors(this.form, this.apiErrors),
          general: [],
        };
        return;
      }

      this.isPayLoading = true;
      const response = await this.pay({
        stripe: this.stripe,
        card: this.card,
        sendMoreAgree: this.sendMoreAgree,
        ...this.form,
      });
      this.isPayLoading = false;

      if (response.success) {
        this.closeDialog();
        this.$toasted.show('You paid the most!', {
          type: 'success',
          icon: 'check-circle',
        });
      }
      else {
        this.cardErrors = response.message;
      }
    },
  },
};
</script>

<style scoped>
.StripeElement {
  @apply border border-gray-400 rounded px-3 py-3;
}
</style>
