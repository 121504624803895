const { fromPairs, get, set, concat, uniqBy, zipObject } = require('lodash');

class Validator {
  /**
   * Constructor.
   *
   * @param {Array<String>} fields - A list of fields to validate for this form.
   */
  constructor(fields) {
    this.fields = fields;
    this.form = {};
  }

  /**
   * If the form is valid.
   *
   * Ignore apiErrors in this, because you will frequently want to continue to show api errors
   * (duplicate user name), but allow the user to resubmit the form.
   *
   * @param {Object} form - The form to examine.
   *
   * @return {Boolean}
   */
  valid(form) {
    return Object.keys(this.errors(form, {})).length === 0;
  }

  /**
   * Errors arising from an invalid form.
   *
   * @param {Object} form - The form to examine.
   * @param {Object} apiErrors - The apiErrors to incorporate.
   * @param {String} specificField - A specific field to check, if not the whole form.
   *
   * @return {Object}
   */
  errors(form, apiErrors, specificField = false) {
    // Save the form for use in validators/rules.
    this.form = form;
    let errors;

    // Return errors for a single, specific field
    if (specificField) {
      errors = uniqBy(concat(
        this.getFirstFailingRule(specificField, form),
        get(apiErrors, specificField, []),
      ), 'code');
    }
    // Return errors for the entire form
    else {
      const rulesErrorArray = this.fields.map(
        (field) => [field, this.getFirstFailingRule(field, form)],
      );

      errors = fromPairs(rulesErrorArray.filter(([key, error]) => error.length));

      // Merge apiErrors into validator errors
      Object.keys(apiErrors).forEach((key) => {
        if (apiErrors[key].length) {
          set(errors, key, concat(get(errors, key, []), apiErrors[key]));
        }
      });

      errors = this.uniqueErrors(errors);
    }

    return errors;
  }

  /**
   * Creates a error object that is free of duplicate errors, by removing errors with duplicate
   * code properties.
   *
   * Error objects may have duplicate errors if API errors are being concatenated with form
   * errors.  Usually, you should prevent a user from submitting a form until the form values are
   * valid, but some cases may require for the error handling to happen exclusively on the server.
   *
   * @param {Object} errors - The errors to de-duplicate
   *
   * @return {Object}
   */
  uniqueErrors(errors) {
    const keys = [];
    const values = [];

    Object.entries(errors).forEach(([key, value]) => {
      keys.push(key);
      values.push(uniqBy(value, 'code'));
    });

    return zipObject(keys, values);
  }

  /**
   * Gets the first failing rule for the provided field.
   *
   * @param {String} field - The field to get the errors for.
   * @param {Object} form - The form to use to validate data.
   *
   * @return {Array<Object>}
   */
  getFirstFailingRule(field, form) {
    if (! get(this.rules, field)) {
      throw new Error(`No rules have been defined for ${field} in validator.`);
    }

    // eslint-disable-next-line no-restricted-syntax
    for (const rule of this.rules[field]) {
      const errorObj = rule.validate(field, form);
      if (errorObj) {
        return [errorObj];
      }
    }

    return [];
  }
}

module.exports = {
  Validator,
};
