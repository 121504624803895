<template>
  <div>
    <div
      v-if="states.length > 0"
      class="w-full"
    >
      <div class="text-blue-500 text-xs">
        {{ label }}
      </div>
      <select
        v-if="states.length > 0"
        class="w-full border-gray-400
          focus:active:border-blue-500 focus:active:outline-none"
        :disabled="disabled"
        :value="value"
        @change="changed"
      >
        <option
          v-for="state in states"
          :key="state"
          :value="state"
        >
          {{ state }}
        </option>
      </select>

      <!-- eslint-disable vue/no-v-html -->
      <div
        v-if="errorText.length"
        class="text-red-500 text-xs"
        v-html="errorText"
      />
      <!-- eslint-enable -->
    </div>

    <text-input
      v-else
      class="w-full"
      input-id="state"
      :value="value"
      :label="label"
      :error-text="errorText"
      :disabled="disabled"
      @input="textChanged"
    />
  </div>
</template>

<script>
import TextInput from '#ui/components/TextInput';

import { states } from '#config/geography';

export default {
  name: 'StateInput',

  components: {
    TextInput,
  },

  props: {
    /**
     * @type {String} A descriptive label.
     */
    label: {
      type: String,
      required: true,
    },

    /**
     * @type {String} Error text to display on error.
     */
    errorText: {
      type: String,
      default: '',
    },

    /**
     * @type {Boolean} If the component is disabled.
     */
    disabled: {
      type: Boolean,
      default: false,
    },

    /**
     * @type {String} The value of the select input.
     */
    value: {
      type: String,
      default: '',
    },

    /**
     * @type {String} The country to display states for.
     */
    country: {
      type: String,
      default: '',
    },
  },

  data: () => ({
    textTimeoutId: null,
  }),

  computed: {
    states() {
      return Object.keys(states).includes(this.country) ? states[this.country] : [];
    },
  },

  methods: {
    /**
     * When state dropdown is changed, emit input to update v-model, and change event so that we can
     * update taxes immediately.
     *
     * @param {Event} event - The event that was triggered.
     */
    changed(event) {
      this.$emit('input', event.target.value);
      this.$emit('change', event);
    },

    /**
     * When user is typing in text for state, wait half a second after they've finished typing
     * before we send the change event. This prevents every single keystroke from triggering the
     * tax update.
     *
     * The input is emitted immediately, so the component's state is always correct.
     *
     * @param {String} text - The changed text.
     */
    textChanged(text) {
      this.$emit('input', text);
      clearTimeout(this.textTimeoutId);
      this.textTimeoutId = setTimeout(() => this.$emit('change', text), 500);
    },
  },
};
</script>
